<template>
<div class="animated fadeIn">
    <div id="snackbar"></div>    
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Gogo Settings</strong> <small> Form</small>
          </div>
          <b-form v-on:submit.prevent.self="submit">
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="title">Threshold <span class="text-danger">*</span></label>
                <b-form-input  type="number" step="any" required v-model="settings.MinSeats" id="title"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="status">Show Seat Layout Status <span class="text-danger">*</span></label>
                <br>
                <c-switch class="mx-1" color="primary" v-model="settings.SeatLayoutOnOff" defaultChecked variant="3d" label v-bind="labelIcon" />
              </b-form-group>
            </b-col>
            <!-- <b-col sm="4">
              <b-form-group>
                <label for="status">Setting Status <span class="text-danger">*</span></label>
                <br>
                <c-switch class="mx-1" color="primary" v-model="settings.GogoSettingIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />
              </b-form-group>
            </b-col> -->
          </b-row>
          
          <b-row>
              <b-col sm="12">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> Submit</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>

         <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Gogo Settings
        <div class="card-header-actions">
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
          <!-- <div slot="GogoSettingIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.GogoSettingIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.GogoSettingID)"/>
          </div> -->
          <div slot="SeatLayoutOnOff" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.SeatLayoutOnOff" @change.native="changeStatus(props.row.GogoSettingID)" variant="3d" label v-bind="labelIcon"/>
          </div>
          <!-- <div slot="Date" slot-scope="props">
            <i class="fa fa-edit" @click="editSetting(props.row.GogoSettingID)"></i>
          </div> -->
        </v-client-table>
      </b-card-body>
    </b-card>
      </b-col>
    </b-row>
</div>
</template>

<script>
import Vue from 'vue'
import { Switch as cSwitch } from '@coreui/vue'
import {ClientTable, Event} from 'vue-tables-2'
import MasterService from '@/services/MasterService'
Vue.use(ClientTable)

export default {
    name: 'GogoSettings',
    components: {
        cSwitch,
        ClientTable,
        Event
    },
    data() {
      return {
        settings: {
          GogoSettingID: null,
          MinSeats: '',
          SeatLayoutOnOff: true,
          // GogoSettingIsActive: true
        },
        labelIcon: {
            dataOn: '\u2713',
            dataOff: '\u2715'
        },
        columns: ['MinSeats', 'SeatLayoutOnOff', 'Date'],
        data: [],
        options: {
          headings: {
            MinSeats: 'Threshold',
            SeatLayoutOnOff: 'Seat Layout Status',
            Date: 'Date',
            // GogoSettingIsActive: 'Status'
          },
          sortable: ['MinSeats'],
          filterable: ['MinSeats'],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          pagination: {
            chunk: 5,
            edge: false,
            nav: 'scroll'
          }
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    mounted() {
      this.getGogoSettings()
    },
    methods: {
      async editSetting() {
        // document.getElementById('editThreshold').style.display= 'block';
       let res = await MasterService.getGogoSettings()
        this.settings = res.data[0];
      },
      async getGogoSettings() {
        let res = await MasterService.getGogoSettings()
        this.data = res.data
      }, 
      async submit() {
        let res = await MasterService.addGogoSettings(this.settings)
        console.log('res :', res);
        var x = document.getElementById("snackbar");
        if(res.data.Status == true) {

        this.settings = {
          GogoSettingID: null,
          MinSeats: '',
          SeatLayoutOnOff: true,
          // GogoSettingIsActive: true
        }
        }
        // Add the "show" class to DIV
        x.className = "show";
        x.innerHTML = res.data.Message
        // After 3 seconds, remove the show class from DIV
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
          this.getGogoSettings()
        },
        async changeStatus(ID) {
        let res = await MasterService.activateOrDeactivate(ID, 'GogoSettings')
        var x = document.getElementById("snackbar");
        // Add the "show" class to DIV
        x.className = "show";
        x.innerHTML =res.data.Message
        // After 3 seconds, remove the show class from DIV
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
      }
    
      
      }
}
</script>

<style>
 .fa-edit {
    cursor: pointer;
    font-size: 18px;
  }
  .fa-edit:hover {
    color: #20a8d8;
  }
</style>
